import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function CRMExpression() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h3>CRM Expression</h3>
                        {/* <nav
                        aria-label="breadcrumb"
                        className="d-inline-block mt-2 mb-15"
                      >
                        <ul className="breadcrumb-item-content mb-0">
                          <li className="breadcrumb-item">
                            <Link to="/">Services</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            CRMExpression
                          </li>
                        </ul>
                      </nav> */}
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h2 className="section-title">
                            Welcome to KRSInfoTech
                          </h2>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          Here is the best technology for managing all your
                          company’s relationships and interactions with
                          customers and potential customers
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/crm.jpg"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* Vision */}
      <div className="vision-area pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
                <span>FEATURES</span>
                <span className="border-right-1" /> */}
              <h1 className="text-center">
                About <span>CRM Expression Software</span>
              </h1>
              <p>
                For businesses of all sizes, acquiring, retaining, and
                supporting customers is more challenging than ever
                before.Activities that were already complex have become
                highly-complicated, multi-faceted workflows and procedures that
                are difficult to coordinate, track and manage. Marketing teams
                must plan and develop an increasing number of sophisticated
                campaigns, and deliver them through multiple mediums. Sales reps
                must follow-up on hundreds of new leads, Support staff must
                rapidly resolve a growing volume of customer problems and
                issues. And, management must oversee customer-facing operations
                across all departments, and ensure that all client interactions
                are handled in a responsive and protective manner.
              </p>
            </div>
            {/* /col */}
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
                <span>FEATURES</span>
                <span className="border-right-1" /> */}
              <h1 className="text-center">
                What is <span>CRM Expression Software</span>?
              </h1>
              <p>
                For businesses of all sizes, acquiring, retaining, and
                supporting customers is more challenging than ever
                before.Activities that were already complex have become
                highly-complicated, multi-faceted workflows and procedures that
                are difficult to coordinate, track and manage. Marketing teams
                must plan and develop an increasing number of sophisticated
                campaigns, and deliver them through multiple mediums. Sales reps
                must follow-up on hundreds of new leads, Support staff must
                rapidly resolve a growing volume of customer problems and
                issues. And, management must oversee customer-facing operations
                across all departments, and ensure that all client interactions
                are handled in a responsive and protective manner.
              </p>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}

      {/* Vision */}
      <div className="vision-are pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div>
              <div
                className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
                data-wow-delay="0.1s"
              >
                {/* <span className="border-left-1" />
                <span>FEATURES</span>
                <span className="border-right-1" /> */}
                <h1>
                  Key Benefits <span>for Your Company</span>
                </h1>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
          {/* row */}
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12">
              <div className="mr-lg-5">
                <img
                  src="images/down/benefits.png"
                  className="img-fluid wow fadeInLeft animated"
                  data-wow-delay="0.2s"
                  alt=""
                />
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ml-lg-5">
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                 
                  <div className="media-body content ml-3">
                    <p className="para mb-0">
                      CRM Expression can help your business enhance the way it
                      interacts with and services customers through multiple new
                      channels. With our CRM suite, you can deploy company-wide
                      best practices, implementing consistent, effective, and
                      efficient processes across departments and business units,
                      to ensure positive end-to-end,experience for your
                      customers. This dramatic improvement in service quality
                      will help you increase loyalty, and boost customer
                      profitability.
                    </p>
                  </div>
                </div>
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 mt-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                
                  <div className="media-body content ml-3">
                    <p className="para mb-0">
                      A CRM solution can also deliver significant increases in
                      staff productivity and reduce the costs associated with
                      attracting, retaining, and supporting clients. Sales
                      automation, to help sales reps more effectively manages
                      their contacts, opportunities and related activities.
                      Customer service or contact centre automation, to help
                      customer service teams deliver rapid, high-quality
                      service, while improving the success of up-sell and
                      cross-sell programs. Help desk automation that enables
                      support staff to rapidly respond to and track customer
                      problems and issues.Reporting and analysis, so management
                      can obtain complete visibility into all customer-related
                      activities within and across each department.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}

      {/* Services all */}

      <div className="features-area pt-100 pb-75">
        {/* Container */}

        <div className="container">
          <div
            className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
            data-wow-delay="0.1s"
          >
            <span className="border-left-1" />
            <span>FEATURES</span>
            <span className="border-right-1" />
            <h1>
              Features of <span>CRM Expression</span>
            </h1>
          </div>
          {/* row */}
          <div className="row justify-content-center">
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6 ">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                {/* <div className="icon">
                  <i className="lni lni-eye" />
                </div> */}
                <h3>Sales Automation</h3>
                <p>
                  {" "}
                  Campaign management & reporting Calling & e-mail marketing
                  Lead & list management Detailed Campaign Analysis.{" "}
                </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg2">
                  <i className="las la-layer-group" />
                </div> */}
                <h3>Marketing Automation </h3>
                <p>
                  {" "}
                  Campaign management & reporting Calling & e-mail marketing
                  Lead & list management Detailed Campaign Analysis{" "}
                </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.6s"
              >
                {/* <div className="icon bg3">
                  <i className="las la-leaf" />
                </div> */}
                <h3>Helpdesk Automation</h3>
                <p> Case management Reporting Ticket Tracking </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                {/* <div className="icon bg4">
                  <i className="las la-money-bill" />
                </div> */}
                <h3>Product Management</h3>
                <p>
                  {" "}
                  Purchase and Sales Order Management Invoice Management Quotes
                  & order entry Inventory management Vendor Management Price
                  List Payment{" "}
                </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg5">
                  <i className="las la-cloud" />
                </div> */}
                <h3>Activity Management</h3>
                <p> Scheduler Meeting Calls Notes </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg5">
                  <i className="las la-cloud" />
                </div> */}
                <h3>In-depth Reporting and Dashboard</h3>
                <p> Reporting Personalized Dashboard </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg5">
                  <i className="las la-cloud" />
                </div> */}
                <h3>Users and User Role Management</h3>
                <p> User Management User Role Management Team Management </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg5">
                  <i className="las la-cloud" />
                </div> */}
                <h3>Other Features</h3>
                <p>
                  {" "}
                  Customization Import Export Capability Emailing Document
                  Management Project Management Contract Management{" "}
                </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg5">
                  <i className="las la-cloud" />
                </div> */}
                <h3>Activity Management</h3>
                <p> Scheduler Meeting Calls Notes</p>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Services all */}

      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default CRMExpression;
