import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function BusinessOperations() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        {/* <h3>Software Design Solutions</h3> */}
                        {/* <nav
                    aria-label="breadcrumb"
                    className="d-inline-block mt-2 mb-15"
                  >
                    <ul className="breadcrumb-item-content mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Services</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        CRMExpression
                      </li>
                    </ul>
                  </nav> */}
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h1 className="section-title">
                            24/7 Business Operations Support
                          </h1>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          At KRS Infotech, we understand the importance of
                          uninterrupted business operations for our valued
                          customers. That's why we offer 24/7 live support to
                          ensure that your business keeps running smoothly at
                          all times. Our dedicated support team is always
                          available to address any technical issues, provide
                          expert guidance, and assist with any challenges that
                          may arise.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/Business-Operations.jpg"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* work */}
      <div className="work-area pb-100 pt-mb-50 pt-sm-50 pb-mb-50 pb-sm-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-12">
              {/* col */}
              <div className="col-lg-12">
                <div
                  className="section-title-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <h1>
                    24/7 Business Operations <span> Support </span>
                  </h1>
                  <p>
                    With our best quality live support, you can rest assured
                    that you have a reliable partner by your side, ready to
                    resolve any issues promptly and efficiently. We prioritize
                    your business's success and strive to deliver exceptional
                    support that exceeds your expectations. Trust KRS Infotech
                    to provide the reliable and responsive assistance you need
                    to keep your business operations running seamlessly around
                    the clock.
                  </p>
                </div>
              </div>
              {/* col */}
            </div>

            {/* <div className="pt-70">
            <a href="#" className="theme-btn clr-1 theme-btn-bg">
              Read More
            </a>
          </div> */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        {/* <img
        src="images/bg/work.png"
        alt=""
        className="work__bg wow fadeInRight animated"
      /> */}
      </div>
      {/* /work */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default BusinessOperations;
