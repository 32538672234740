import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function StrategicDevOpsPractices() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h1>Strategic DevOps Practices</h1>
                        <p
                          className="text-muted wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          Established in 2013, KRS Infotech has been at the
                          forefront of delivering exceptional technology
                          services across a wide spectrum of industries. With a
                          commitment to excellence and innovation, we have
                          established enduring partnerships with leading
                          entities, providing cutting-edge solutions and
                          expertise.
                        </p>
                        <p
                          className="text-muted wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          At KRS Infotech, we take great pride in delivering the
                          highest quality software design solutions to our
                          valued clients. With our team of experienced
                          professionals and a focus on innovation, we strive to
                          exceed customer expectations and provide cutting-edge
                          solutions that drive business growth. Our commitment
                          to quality is evident in every project we undertake,
                          as we leverage the latest technologies and industry
                          best practices to ensure the successful delivery of
                          software design solutions that are scalable,
                          efficient, and tailored to meet specific client needs.
                          Trust us to transform your ideas into reality with our
                          unwavering dedication to excellence, attention to
                          detail, and a customer-centric approach. Experience
                          the difference of KRS Infotech's best quality software
                          design solutions and unlock new possibilities for your
                          business.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/StrategicDevOpsPractices.png"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default StrategicDevOpsPractices;
