import { Route, Routes } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Consulting from "./pages/Consulting";
import Sapmodules from "./pages/Sapmodules";
import CRMExpression from "./pages/CRMExpression";
import { useEffect, useState } from "react";
import Loader from "./pages/componet/Loader";
import SoftwareDesign from "./pages/SoftwareDesign";
import TestingServices from "./pages/TestingServices";
import SoftwareDevelopment from "./pages/SoftwareDevelopment";
import BusinessOperations from "./pages/BusinessOperations";
import WorkingModels from "./pages/WorkingModels";
import ROITechniques from "./pages/ROITechniques";
import SCClearedResources from "./pages/SCClearedResources";
import StrategicDevOpsPractices from "./pages/StrategicDevOpsPractices";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or any asynchronous task
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/CRMExpression" element={<CRMExpression />} />
          <Route path="/Consulting" element={<Consulting />} />
          <Route path="/Sapmodules" element={<Sapmodules />} />
          <Route path="/SoftwareDesign" element={<SoftwareDesign />} />
          <Route path="/BusinessOperations" element={<BusinessOperations />} />
          <Route path="/TestingServices" element={<TestingServices />} />
          <Route
            path="/SoftwareDevelopment"
            element={<SoftwareDevelopment />}
          />
          <Route path="/WorkingModels" element={<WorkingModels />} />
          <Route path="/ROITechniques" element={<ROITechniques />} />
          <Route path="/SCClearedResources" element={<SCClearedResources />} />
          <Route path="/StrategicDevOpsPractices" element={<StrategicDevOpsPractices />} />

          {/* "homepage": "https://www.krsinfotech.co.uk/", */}
        </Routes>
      )}
    </>
  );
}

export default App;
