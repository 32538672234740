import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function SCClearedResources() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h2>
                          Unlocking Trusted Expertise for Sensitive Projects
                        </h2>

                        <p
                          className="text-muted wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          At KRS Infotech, we are committed to providing SC
                          cleared resources to meet the stringent security needs
                          of our clients. Our SC cleared professionals undergo
                          extensive screening and vetting, ensuring that they
                          meet the highest standards of security clearance,
                          enabling them to collaborate on sensitive projects in
                          industries such as defense, government, and highly
                          regulated sectors.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/SC-Cleared-Resources.jpg"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* Clearance */}
      <div className="work-area pb-100">
        <div className="container">
          <div className="row">
            <div
              className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              <h1>
                Advantages of <span>SC Cleared Resources</span>
              </h1>
            </div>

            <div
              className=" media pre-card rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
              data-wow-delay="0.2s"
            >
              <div
                className="section-title-left wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <h3>
                  Leveraging SC cleared resources offers a range of{" "}
                  <span style={{ color: "#f84257" }}>benefits, including:</span>
                </h3>
                <div className="points p-4">
                  <ul>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>
                        Enhanced Data Security: Our SC cleared resources uphold
                        the strictest data security measures, ensuring the
                        protection of sensitive information and compliance with
                        regulatory standards.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="points p-4">
                  <ul>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>
                        Regulatory Compliance: Working with SC cleared
                        professionals provides an added layer of compliance,
                        aligning with the most stringent industry and
                        governmental regulations.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="points p-4">
                  <ul>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>
                        Trust and Reliability: Clients can rely on our SC
                        cleared resources to deliver steadfast and reliable
                        support, especially in projects where security and
                        compliance are paramount.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Clearance */}

      {/* Industries */}
      <div className="work-area pb-80">
        <div className="container">
          <div className="row">
            <div
              className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              <h1>
                Industries <span>We Serve</span>
              </h1>
            </div>

            <div
              className=" media pre-card rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
              data-wow-delay="0.2s"
            >
              <div
                className="section-title-left wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <h3>
                  Our SC cleared resources cater to a diverse range of{" "}
                  <span style={{ color: "#f84257" }}>
                    industries, including:
                  </span>
                </h3>
                <div className="points p-4">
                  <ul>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>Government and Public Sector</p>
                    </li>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>Defense and Aerospace</p>
                    </li>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>Financial Services and Banking</p>
                    </li>
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>Healthcare and Life Sciences</p>
                    </li>{" "}
                    <li className="wow fadeInUp animated" data-wow-delay="0.4s">
                      <i className="las la-check" />
                      <p>Critical Infrastructure Projects</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Industries */}
      {/* Vision */}
      <div className="vision-area pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              <h1>
                Client Success <span>Stories</span>
              </h1>
            </div>

            {/* /col */}
          </div>
          {/* /row */}
          {/* row */}
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12">
              <div className="mr-lg-5">
                <img
                  src="images/down/Client-Success-Stories.png"
                  className="img-fluid wow fadeInLeft animated"
                  data-wow-delay="0.2s"
                  alt=""
                />
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ml-lg-5">
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                
                  <div className="media-body content ml-3">
                    <h5>
                      Providing SC Cleared Testing Resources to Boeing Defence
                      UK
                    </h5>
                    <p className="para mb-0">
                      At KRS Infotech, we led a significant collaborative effort
                      with Boeing Defence UK, delivering SC cleared testing
                      resources to streamline testing practices within a
                      critical government integration project. This initiative
                      not only underscored our commitment to enhancing data
                      security and compliance but also played a pivotal role in
                      elevating the testing standards and resilience of the
                      entire project.
                    </p>
                  </div>
                </div>
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 mt-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                 
                  <div className="media-body content ml-3">
                    <h5>
                      Enhancing Data Security in a Critical Infrastructure
                      Initiative
                    </h5>
                    <p className="para mb-0">
                      In a strategic collaboration with Transport for Greater
                      Manchester, KRS Infotech delivered secure, BPSS cleared
                      resources, empowering the successful implementation of a
                      new platform. Our partnership involved critical business
                      analysis and robust testing services, marking a milestone
                      in fortifying and streamlining the new platform's
                      integration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
          <div
            className="section-title text-center ml-50 mr-50  wow fadeInUp animated"
            data-wow-delay="0.1s"
          >
            <p>
              Welcome to the KRS Infotech Ecosystem, where innovation,
              reliability, and cutting-edge solutions converge to propel your
              business to new heights. Our comprehensive suite of features is
              meticulously crafted to ensure that your digital journey is
              seamless, efficient, and tailored to your unique needs.
            </p>
          </div>
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default SCClearedResources;
