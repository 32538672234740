import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div className="navbar-area">
        {/* <div className="acavo-responsive-nav">
          <div className="container">
            <div className="acavo-responsive-menu">
              <div className="logo">
                <Link to="/">
                  <img src="images/logo/a-logo.png" alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="acavo-responsive-nav">
          {/* Container */}
          <div className="container">
            <div
              className={`acavo-responsive-menu mean-container ${isMenuOpen ? "meanmenu-reveal-open" : ""
                }`}
            >
              <div className="mean-bar">
                <Link className="meanmenu-reveal" onClick={toggleMenu}>
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </Link>
                <nav
                  className={`mean-nav ${isMenuOpen ? "mean-nav-open" : ""}`}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        to="/"
                        activeclassname="active"
                        className="nav-link "
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeclassname="active"
                        to="/SCClearedResources"
                        className="nav-link"
                      >
                        SC Cleared Resources
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/Services"
                        activeclassname="active"
                        className="nav-link"
                      >
                        Services <i className="las la-angle-down" />
                      </NavLink>
                      <ul
                        className="dropdown-menu"
                        style={{ display: "block" }}
                      >
                        <li className="nav-item">
                          <NavLink
                            to="/SoftwareDevelopment"
                            activeclassname="active"
                            className="nav-link"
                          >
                            Software Development
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/TestingServices"
                            activeclassname="active"
                            className="nav-link"
                          >
                            Testing Services
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/Consulting"
                            activeclassname="active"
                            className="nav-link"
                          >
                            Consulting Services
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/Sapmodules"
                            activeclassname="active"
                            className="nav-link"
                          >
                            SAP Modules
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/BusinessOperations"
                            activeclassname="active"
                            className="nav-link"
                          >
                            24/7 Business Operations Support
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/CRMExpression"
                            activeclassname="active"
                            className="nav-link"
                          >
                            {/* CRMExpression */}
                            CRM Expression
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/SoftwareDesign"
                            activeclassname="active"
                            className="nav-link"
                          >
                            Software Design Solutions
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/WorkingModels"
                            activeclassname="active"
                            className="nav-link"
                          >
                            Working Models
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/ROITechniques"
                            activeclassname="active"
                            className="nav-link"
                          >
                            ROI techniques
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/StrategicDevOpsPractices"
                            activeclassname="active"
                            className="nav-link"
                          >
                            Strategic DevOps Practices
                          </NavLink>
                        </li>
                      </ul>
                      <NavLink
                        className="mean-expand"
                        activeclassname="active"
                        to="/Services"
                        style={{ fontSize: 18 }}
                      >
                        +
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/About"
                        activeclassname="active"
                        className="nav-link"
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item mean-last">
                      <NavLink
                        to="/Contact"
                        activeclassname="active"
                        className="nav-link"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="logo">
                <Link to="/">
                  <img src="images/logo/mobile-logo.png" alt="logo" />
                </Link>
                <Link>
                  <img src="images/logo/JOSCAR-logo-mobile.png" alt="logo" />
                </Link>
              </div>
            </div>
          </div>
          {/* /Container */}
        </div>

        <div className="acavo-nav">
          {/* Container */}
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
                <img src="images/logo/logo-aa.png" alt="logo" width={100} />
              </Link>
              <div className="navbar-brand">
                <img src="images/logo/JOSCAR-logo.png" alt="logo" width={100} />
              </div>
              <div className="collapse navbar-collapse mean-menu">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink
                      to="/"
                      activeclassname="active"
                      className="nav-link"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeclassname="active"
                      to="/SCClearedResources"
                      className="nav-link"
                    >
                      SC Cleared Resources
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/Services"
                      activeclassname="active"
                      className="nav-link"
                    >
                      Services <i className="las la-angle-down" />
                    </NavLink>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          to="/SoftwareDevelopment"
                          activeclassname="active"
                          className="nav-link"
                        >
                          Software Development
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/TestingServices"
                          activeclassname="active"
                          className="nav-link"
                        >
                          Testing Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Consulting"
                          activeclassname="active"
                          className="nav-link"
                        >
                          Consulting Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Sapmodules"
                          activeclassname="active"
                          className="nav-link"
                        >
                          SAP Modules
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/BusinessOperations"
                          activeclassname="active"
                          className="nav-link"
                        >
                          24/7 Business Operations Support
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/CRMExpression"
                          activeclassname="active"
                          className="nav-link"
                        >
                          CRM Expression
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/SoftwareDesign"
                          activeclassname="active"
                          className="nav-link"
                        >
                          Software Design Solutions
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/WorkingModels"
                          activeclassname="active"
                          className="nav-link"
                        >
                          Working Models
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/ROITechniques"
                          activeclassname="active"
                          className="nav-link"
                        >
                          ROI techniques
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/StrategicDevOpsPractices"
                          activeclassname="active"
                          className="nav-link"
                        >
                          Strategic DevOps Practices
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeclassname="active"
                      to="/About"
                      className="nav-link"
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/Contact"
                      activeclassname="active"
                      className="nav-link"
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          {/* /Container */}
        </div>
      </div>
    </>
  );
}

export default Header;
