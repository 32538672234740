import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function Consulting() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h3>CONSULTING</h3>
                        {/* <nav
                        aria-label="breadcrumb"
                        className="d-inline-block mt-2 mb-15"
                      >
                        <ul className="breadcrumb-item-content mb-0">
                          <li className="breadcrumb-item">
                            <Link to="/">Services</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            CRMExpression
                          </li>
                        </ul>
                      </nav> */}
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h2 className="section-title">
                            Welcome to KRSInfoTech
                          </h2>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          IT consulting services that will help you improve your
                          software architecture, create a tech-driven digital
                          strategy, and many more.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/CONSULTING.png"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* Vision */}
      <div className="vision-area pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
                <span>FEATURES</span>
                <span className="border-right-1" /> */}
              <h1 className="text-center">
                OUR <span>IT CONSULTING SERVICES</span>
              </h1>
              <p>
                We offer IT consulting services that will help you improve your
                software architecture, create a tech-driven digital strategy,
                and improve operations by optimising your software portfolio.
                Our software engineers will finish your digital transformation
                journey through careful planning and effective execution of the
                outlined IT strategy.
              </p>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}

      {/* Services all */}
      <div className="features-area pb-75">
        {/* Container */}

        <div className="container">
          {/* <div
            className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
            data-wow-delay="0.1s"
          >
            <span className="border-left-1" />
            <span>FEATURES</span>
            <span className="border-right-1" />
            <h1>
              Features of <span>CRMExpression</span>
            </h1>
          </div> */}
          {/* row */}
          <div className="row justify-content-center">
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height-1 with-border wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                {/* <div className="icon">
                  <i className="lni lni-eye" />
                </div> */}
                <h3>ENTERPRISE ARCHITECTURE ADVISOR</h3>
                <p>
                  {" "}
                  Our Enterprise Architecture experts can help you make the
                  transition from outdated and ineffective IT delivery systems
                  and architectures to a cloud-based infrastructure that
                  streamlines delivery and allows for implementation of new
                  technologies into your software strategy.{" "}
                </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height-1 with-border wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                {/* <div className="icon bg2">
                  <i className="las la-layer-group" />
                </div> */}
                <h3>IT STRATEGY CONSULTING & NEW TECH</h3>
                <p>
                  {" "}
                  Today, you need to ensure the consistent delivery of
                  high-quality IT products and services for your enterprise
                  workflow and your customers. You also need to implement new
                  technologies to stay competitive and meet the demands of
                  modern users. Our IT solutions consulting will help your
                  strike the balance between these two objectives, ensuring they
                  don’t conflict between one another.{" "}
                </p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div
                className="features-item box-max-height-1 with-border wow fadeInUp animated"
                data-wow-delay="0.6s"
              >
                {/* <div className="icon bg3">
                  <i className="las la-leaf" />
                </div> */}
                <h3>SOFTWARE PORTFOLIO CONSULTING </h3>
                <p>
                  {" "}
                  Our IT consulting advisers will conduct a deep analysis of how
                  your business and employees use your existing enterprise
                  software, as well as of your enterprise mobile strategy. Then,
                  they will provide suggestions for the required changes, new
                  developments.
                </p>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Services all */}

      {/* Vision */}
      <div className="vision-area pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
                <span>FEATURES</span>
                <span className="border-right-1" /> */}
              <h1 className="text-center">
                OUR <span>IT CONSULTING PROCESS</span>
              </h1>
              <p>
                For more than 10 years now Intellectsoft has been helping
                organizations of different scale and structure to improve and
                modernise their IT strategies. Some of our IT experts have
                worked in the industry for over fifteen years. This has allowed
                us to craft an effective approach to IT consulting that enables
                us to deliver maximum benefit in the shortest time
              </p>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}

      {/* Features Area */}
      <div className="features-area pb-75">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row justify-content-center">
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <div className="icon">
                  <i className="las la-search" />
                </div>
                <h3>ANALYSIS</h3>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                <div className="icon bg2">
                  <i className="las la-shield-alt" />
                </div>
                <h3>STRATEGY</h3>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.6s"
              >
                <div className="icon bg3">
                  <i className="las la-fingerprint" />
                </div>
                <h3>PERFORMANCE</h3>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.8s"
              >
                <div className="icon bg4">
                  <i className="las la-gem" />
                </div>
                <h3>IMPROVEMENTS</h3>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Features Area */}

      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default Consulting;
