import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function ROITechniques() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* contain */}
      <div className="vision-area pt-100" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
            <span>FEATURES</span>
            <span className="border-right-1" /> */}
              <h1 className="text-center">
                ROI <span> Techniques</span>
              </h1>
              <p>
                At KRS Infotech, we are committed to delivering the best value
                for the investments our clients make. We pride ourselves on
                following the best Return on Investment (ROI) techniques to
                ensure that every dollar our clients spend with us yields
                substantial benefits and drives their business forward.
              </p>
              <p>
                Our experienced team understands the importance of maximizing
                ROI in every project we undertake. We employ a strategic and
                data-driven approach to analyze and identify areas where
                investments can generate the greatest returns. By leveraging our
                expertise, industry insights, and cutting-edge technologies, we
                help our clients achieve the best outcome for their investment.
              </p>
              <p>
                At KRS Infotech, we emphasize transparency and collaboration
                throughout our engagements. We work closely with our clients to
                understand their goals, align expectations, and optimize their
                investment strategy. Our dedicated professionals provide
                personalized guidance, offering recommendations and insights
                that focus on delivering measurable and sustainable returns.
              </p>
              <p>
                With a proven track record of success, we have helped numerous
                clients achieve exceptional ROI on their investments. From
                technology implementations and process improvements to digital
                transformations and business solutions, our services are
                designed to generate significant value and drive long-term
                growth.
              </p>
              <p>
                Choose KRS Infotech as your trusted partner, and rest assured
                that your investment will be optimized for the best ROI.
                Experience our expertise, commitment to results, and unwavering
                dedication to delivering unmatched value for your money.
              </p>
              <p>
                Contact us today to explore how KRS Infotech can help you
                achieve remarkable ROI in your digital initiatives and business
                endeavours.
              </p>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div> 
        {/* /Container */}
      </div>
      {/* /contain */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default ROITechniques;
