import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

function Home() {
  const options = {
    loop: true,
    margin: 38,
    responsiveClass: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {  
        items: 6,
      },
      1200: {
        items: 6,
      },
    },
  };
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Hero Area */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div
                className="section-heading wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <h2 className="section-title">Trusted brand for</h2>
              </div>
              <h1
                className="hero-2-title gray-600 mb-4 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                Digital Transformation Projects
              </h1>
              <div className="w-75 mb-5 mb-lg-0">
                <p
                  className="text-muted mb-15 pb-5 wow fadeInUp animated"
                  data-wow-delay="0.3s"
                >
                  Three decades of consistent and quality service to clients.
                  Technology solutions that enhances lives of people.
                </p>
              </div>
              <div className="subs-btn wow fadeInUp animated">
                <Link className="theme-btn clr-1 theme-btn-bg" to="/Contact">
                  Get Started <i className="lni lni-chevron-right" />
                </Link>
              </div>
            </div>

            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/home.jpg"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            <div
              className="section-heading wow fadeInUp animated mt-30"
              data-wow-delay="0.2s"
            >
              <h3 className="section-title">
                Empowering Aerospace Excellence: KRS Infotech Partners with
                Boeing Defence UK for Unmatched SAP and Testing Solutions
              </h3>
            </div>

            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Hero Area */}
      {/* Clearance */}
      <div className="counter-area-2 pb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              <h1>
                Unparalleled Security <span>Clearance</span>
              </h1>
            </div>
            <div
              className="mb-80 media pre-card pre-primary rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
              data-wow-delay="0.2s"
            >
              <div className="media-body content ml-3">
                <h5>Providing SC Cleared Resources</h5>
                <p>
                  At KRS Infotech, we take pride in providing SC cleared
                  resources to a number of government departments and
                  organizations.
                </p>
                <p>
                  Central Government, Ministry of Defence, Home Office, Foreign
                  Affairs Office, and more routinely require security clearance,
                  and our capability to fulfil this rigorous need underscores
                  our unwavering commitment to maintaining the highest security
                  standards.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12">
              <div className=" mt-0 mb-md-10 mb-10">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="media pre-card pre-primary rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
                      data-wow-delay="0.2s"
                    >
                      <div className="media-body content ml-3">
                        <h5>National Impact, Global Expertise</h5>
                        <p>
                          Collaborating with prestigious clients including
                          Nationwide Building Society, FIS Global ( Worldpay) ,
                          Mastercard, Transport for Greater Manchester, and
                          Prepay (Edenred Paytech), we've played a pivotal role
                          in shaping the infrastructure of banking, payments,
                          and scheme processing. Our dedication to excellence is
                          further evidenced by our ongoing collaboration with
                          Boeing Defence UK, particularly in the domains of SAP
                          HANA Migration, SAP EWM, and Software Testing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-12">

              <img
                src="images/down/Unparalleled.png"
                className="img-fluid wow fadeInRight animated"
                data-wow-delay="0.2s"
                alt=""
              />

              {/* <div
                className="counter-image-area right-0 mr-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <div className="counter-image">
                  <img
                    src="images/counter/about-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="counter-image">
                  <img
                    src="images/counter/about-4.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* /Clearance */}
      {/* Vision */}
      <div className="vision-area pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12">
              <div className="mr-lg-5">
                <img
                  src="images/down/Ensuring-Security.png"
                  className="img-fluid wow fadeInLeft animated"
                  data-wow-delay="0.2s"
                  alt=""
                />
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ml-lg-5">
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <div className="media-body content ml-3">
                    <h5>Elevating Standards, Ensuring Security</h5>
                    <p>
                      At KRS Infotech, we take pride in offering SC cleared
                      resources to meet the rigorous security needs of our
                      clients. Our profound commitment to security is further
                      evident in our capability to conduct SC clearance,
                      underscoring our unwavering dedication to maintaining the
                      highest security standards.
                    </p>
                  </div>
                </div>
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 mt-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                  <div className="media-body content ml-3">
                    <h5>Proud Members of the ADS Consortium</h5>
                    <p>
                      As active members of the <Link to="https://www.adsgroup.org.uk/" className="link-hover" target="_blank"> ADS Consortium</Link>—an alliance that
                      symbolizes the dynamism, innovation, and prosperity within
                      the aerospace, defence, security, and space sectors—we
                      continue to contribute to the advancement and leadership
                      of the Aerospace, Defence, and Security industries.
                    </p>
                    <p> 
                      At KRS Infotech, we don't just deliver solutions; we forge
                      enduring partnerships that drive innovation and
                      transformation. Join us as we elevate possibilities and
                      shape the future, one success story at a time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}
      {/* Counter */}
      <div className="counter-area-2 pt-100 pb-100">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="counter-content mt-0 mb-md-10 mb-10">
                {/* row */}
                <div className="row">
                  {/* col */}
                  <div className="col-lg-12">
                    <div
                      className="section-heading text-center wow fadeInUp animated"
                      data-wow-delay="0.2s"
                    >
                      <h2 className="section-title">
                        Reaching Customer Service.
                      </h2>
                    </div>
                  </div>
                  {/* col */}
                </div>
                {/* /row */}
                {/* row */}
                <div className="row mt-30">
                  {/* col */}
                  <div className="col-md-6 col-sm-6 col-12 mb-20">
                    <div
                      className="counter-funfact wow fadeInUp animated"
                      data-wow-delay="0.2s"
                    >
                      <div className="number">
                        <span className="counter">01</span>
                      </div>
                      <h6 className="text">Outstanding Service</h6>
                      <p>
                        We are committed to providing the highest quality
                        support and customer services through a collaborative
                        client relationships.
                      </p>
                    </div>
                  </div>
                  {/* /col */}
                  {/* col */}
                  <div className="col-md-6 col-sm-6 col-12 mb-20">
                    <div
                      className="counter-funfact wow fadeInUp animated"
                      data-wow-delay="0.4s"
                    >
                      <div className="number">
                        <span className="counter">02</span>
                      </div>
                      <h6 className="text">Dedicated Team</h6>
                      <p>
                        Our team of qualified experts deliver proactive services
                        and professional advise to support your business plan.
                      </p>
                    </div>
                  </div>
                  {/* /col */}
                  {/* col */}
                  <div className="col-md-6 col-sm-6 col-12 mb-20">
                    <div
                      className="counter-funfact wow fadeInUp animated"
                      data-wow-delay="0.6s"
                    >
                      <div className="number">
                        <span className="counter">03</span>
                      </div>
                      <h6 className="text">Smart IT Solutions</h6>
                      <p>
                        We take the time to understand your business and deliver
                        innovative, flexible and tailored IT solutions.
                      </p>
                    </div>
                  </div>
                  {/* /col */}
                  {/* col */}
                  <div className="col-md-6 col-sm-6 col-12 mb-20">
                    <div
                      className="counter-funfact wow fadeInUp animated"
                      data-wow-delay="0.8s"
                    >
                      <div className="number">
                        <span className="counter">04</span>
                      </div>
                      <h6 className="text">Better Value</h6>
                      <p>
                        We combine our skills and expertise to find the right
                        technical solution in line with your budget constraints.
                      </p>
                    </div>
                  </div>
                  {/* /col */}
                </div>
                {/* /row */}
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-6 col-lg-6 col-12">
              <div
                className="counter-image-area right-0 mr-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <div className="counter-image">
                  <img
                    src="images/counter/about-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="counter-image">
                  <img
                    src="images/counter/about-4.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Counter */}
      {/* Vision */}
      <div className="vision-area pt-100 pb-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}

            <div
              className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              <span className="border-left-1" />
              <span>FEATURES</span>
              <span className="border-right-1" />
              <h1>
                Transforming Visions <span>into Reality</span>
              </h1>
              <p>
                Welcome to the KRS Infotech Ecosystem, where innovation,
                reliability, and cutting-edge solutions converge to propel your
                business to new heights. Our comprehensive suite of features is
                meticulously crafted to ensure that your digital journey is
                seamless, efficient, and tailored to your unique needs.
              </p>
            </div>

            {/* /col */}
          </div>
          {/* /row */}
          {/* row */}
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12">
              <div className="mr-lg-5">
                <img
                  src="images/down/vision.png"
                  className="img-fluid wow fadeInLeft animated"
                  data-wow-delay="0.2s"
                  alt=""
                />
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ml-lg-5">
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                 
                  <div className="media-body content ml-3">
                    <h5>Innovative Solutions</h5>
                    <p className="para mb-0">
                      Embark on a journey of digital transformation with KRS
                      Infotech's innovative solutions. From software design to
                      24/7 business operations support, our team of experts is
                      dedicated to providing groundbreaking solutions that drive
                      your business forward.
                    </p>
                  </div>
                </div>
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 mt-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                  
                  <div className="media-body content ml-3">
                    <h5>Unparalleled Security Clearance</h5>
                    <p className="para mb-0">
                      At KRS Infotech, security is paramount. We take pride in
                      offering SC cleared resources to government departments
                      and organizations. Our capability to meet rigorous
                      security standards underscores our commitment to ensuring
                      the highest levels of data protection.
                    </p>
                  </div>
                </div>
                <div
                  className="media pre-card pre-primary rounded align-items-center p-4 mt-4 position-relative overflow-hidden wow fadeInUp animated"
                  data-wow-delay="0.6s"
                >
                  
                  <div className="media-body content ml-3">
                    <h5>Global Impact, National Expertise</h5>
                    <p className="para mb-0">
                      Collaborate with KRS Infotech and join prestigious clients
                      such as Nationwide Building Society, FIS Global
                      (Worldpay), Mastercard, Transport for Greater Manchester,
                      and Boeing Defence UK. Our expertise spans industries,
                      leaving a lasting impact on a global scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="shape-layer">
          <div className="layer layer-one">
            <img src="images/bg/page-bg-2.png" alt="" />
          </div>
        </div>
      </div>
      {/* /Vision */}
      {/* Client Logo */}
      <div className="client-logo-area pt-100 pb-100">
        {/* Container */}
        <div className="container">
          <OwlCarousel {...options} className="owl-theme">
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/bny.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/cv.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/hsbc.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/Boeing.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/FIS-Global.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/Mastercard.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/Nationwide-Building.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="client-logo">
                <div
                  className="client-logo-img wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="images/client-logo/Transport.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
        {/* /Container */}
      </div>
      {/* /Client Logo */}
      {/* Features Area */}
      <div className="features-area  pb-75">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row justify-content-center">
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <div className="icon">
                  <i className="las la-mobile" />
                </div>
                <h3>User Friendly</h3>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                <div className="icon bg2">
                  <i className="las la-shield-alt" />
                </div>
                <h3>Award Winning App</h3>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.6s"
              >
                <div className="icon bg3">
                  <i className="las la-fingerprint" />
                </div>
                <h3>Privacy Protected</h3>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xl-3 col-lg-3 col-sm-3 col-md-3 col-6">
              <div
                className="features-box wow fadeInUp animated"
                data-wow-delay="0.8s"
              >
                <div className="icon bg4">
                  <i className="las la-gem" />
                </div>
                <h3>Lifetime Update</h3>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Features Area */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default Home;
