import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="footer">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row mb-80">
            {/* col */}
            <div className="col-lg-4 col-sm-6">
              <div className="mb-4">
                <Link to="/">
                  <img
                    src="images/logo/logo-aa.png"
                    alt=""
                    className="logo-light"
                    // height={30}
                  />
                </Link>
                <Link to="/">
                  <img
                    src="images/logo/logo-aa.png"
                    alt=""
                    className="logo-dark"
                    // height={22}
                  />
                </Link>
              </div>
              <p className="footer-desc f-15">
                A business has to be involving, it has to be fun, and it has to
                exercise your creative instincts
              </p>
              <ul className="footer-icons list-inline mb-0 mt-4">
                <li className="list-inline-item me-3">
                  <Link to="/" className="">
                    <i className="lni lni-facebook-filled" />
                  </Link>
                </li>
                <li className="list-inline-item me-3">
                  <Link to="/" className="">
                    <i className="lni lni-twitter-filled" />
                  </Link>
                </li>
                <li className="list-inline-item me-3">
                  <Link to="/" className="">
                    <i className="lni lni-instagram-filled" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" className="">
                    <i className="lni lni-youtube" />
                  </Link>
                </li>
              </ul>
            </div>
            {/* /col */}
            {/* /col */}
            <div className="col-lg-7 offset-lg-1">
              {/* row */}
              <div className="row mt-lg-0">
                {/* /col */}
                <div className="col-md-4 mt-4 mt-lg-0">
                  <h4 className="footer-list-title mb-3">Services</h4>
                  <ul className="list-unstyled company-sub-menu">
                    {/* <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/SCClearedResources">SC Cleared Resources</Link>
                    </li>
                    <li>
                      <Link to="/About">About</Link>
                    </li>
                    <li>
                      <Link to="/Services">Services</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact Us</Link>
                    </li> */}

                    <li>
                      <Link to="/SoftwareDevelopment">
                        Software Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/TestingServices">Testing Services</Link>
                    </li>
                    <li>
                      <Link to="/Consulting">Consulting Services</Link>
                    </li>
                    <li>
                      <Link to="/Sapmodules">SAP Modules</Link>
                    </li>
                    <li>
                      <Link to="/BusinessOperations">
                        24/7 Business Operations Support
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* col */}
                {/* col */}
                <div className="col-md-4 mt-4 mt-lg-0">
                  <h4 className="footer-list-title mb-3">
                    <br />
                  </h4>
                  <ul className="list-unstyled company-sub-menu">
                    <li>
                      <Link to="/CRMExpression">CRM Expression</Link>
                    </li>
                    <li>
                      <Link to="/SoftwareDesign">
                        Software Design Solutions
                      </Link>
                    </li>
                    <li>
                      <Link to="/WorkingModels">Working Models</Link>
                    </li>
                    <li>
                      <Link to="/ROITechniques">ROI techniques</Link>
                    </li>
                    <li>
                      <Link to="/StrategicDevOpsPractices">
                        Strategic DevOps Practices
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* col */}
                {/* /col */}
                <div className="col-md-4 mt-4 mt-lg-0">
                  <h5 className="footer-list-title mb-3">Our Address</h5>
                  <ul className="list-unstyled company-sub-menu">
                    <li>
                      <Link>
                        The Long Lodge 265-269 Kingston Road, Wimbledon, London,
                        SW19 3NW
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-link" to="mailto:Azod@example.com">
                        info@krsinfotech.co.uk
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-link" to="tel:+44 7983236578">
                        +44 7983236578
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* /col */}
              </div>
              {/* /row */}
            </div>
            {/* /col */}
          </div>
          {/* /row */}
          {/* row */}
          <div className="row border-top pt-20 pb-40">
            {/* /col */}
            <div className="col-lg-6 mt-3">
              <div className="text-left footer-alt my-3">
                <p>© Copyrights 2024 Tri-techno all rights reserved.</p>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 mt-3">
              <div className="text-right footer-alt my-3">
                <p>
                  Made with By <i className="las la-heart" /> Tri-techno
                </p>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </footer>
      {/* Go top */}
      <div className="go-top-area">
        <div className="go-top-wrap">
          <div className="go-top-btn-wrap">
            <div className="go-top go-top-btn" onClick={scrollToTop}>
              <i className="las la-angle-double-up" />
              <i className="las la-angle-double-up" />
            </div>
          </div>
        </div>
      </div>
      {/* /Go top */}
    </>
  );
}

export default Footer;
