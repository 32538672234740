import React, { useState } from "react";
import Footer from "./componet/Footer";
import Header from "./componet/Header";
import emailjs from "@emailjs/browser";

function Contact() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    // Validate username

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.username.trim()) {
      newErrors.username = "Please enter your name";
    } else if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (!formData.phone.trim()) {
      newErrors.phone = "Please enter your phone number";
    } else if (!formData.subject.trim()) {
      newErrors.subject = "Please enter a subject";
    } else if (!formData.message.trim()) {
      newErrors.message = "Please enter a message";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    const isValid = validateForm();

    if (isValid) {

      var submitButton = document.getElementById("submitbutton");
      submitButton.style.display = "none";

      const templateparams = {
        form_name: formData.username,
        form_email: formData.email,
        to_name: formData.subject,
        message: formData.message,
        phone: formData.phone,
        reply_to: formData.email,
      };

      emailjs
        .send(
          "service_4l9dpsg",
          "template_8p6zl4d",
          templateparams,
          "r-Ce1rHbQfKO8nXdg"
        )
        .then(
          (result) => {
            alert("Form submitted");
            // Get the submit button element by its ID
            var submitButton = document.getElementById("submitbutton");
            // Hide the submit button
            submitButton.style.display = "block";
            // Clear form data
            setFormData({
              username: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            });
            console.log(result.text);
          },
          (error) => {
            alert(error.text);
            console.log(error.text);
          }
        );
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear the error for the current field when the user types
    setErrors({
      ...errors,
      [e.target.name]: undefined,
    });
  };

  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h3>Contact</h3>
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h2 className="section-title">
                            Contact for Premium Business Services
                          </h2>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          Feel free to contact us. A business has to be
                          involving, it has to be fun, and it has to exercise
                          your creative instincts. Start where you are. Use what
                          you have. Do what you can.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/contact.png"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* Contact Area */}
      <div className="contact-area pb-120">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-xxl-4 col-xl-4 col-lg-6">
              <div className="feature_wrapper_02 mb-30">
                <div className="section-title text-left mb-40">
                  <h5>Contact Us</h5>
                  <h2>Let’s start talk about your project</h2>
                </div>
                <ul className="contact-list mb-40">
                  <li style={{ lineHeight: "normal" }}>
                    <i className="las la-map-marker-alt" />
                    <span>
                      The Long Lodge 265-269 Kingston Road, Wimbledon, London,
                      SW19 3NW
                    </span>
                  </li>
                  <li>
                    <i className="las la-phone" />
                    +44 7983236578
                  </li>
                  <li>
                    <i className="las la-envelope" />
                    info@krsinfotech.co.uk
                  </li>
                </ul>
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-xxl-6 offset-xxl-2 col-lg-6">
              <div className="contact-form">
                <form className="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xl-6 mb-25">
                      <input
                        className="contact-input mb-0"
                        type="text"
                        placeholder="Full Your Name"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                      />
                      {errors.username && (
                        <span className="error">{errors.username}</span>
                      )}
                    </div>
                    <div className="col-xl-6 mb-25">
                      <input
                        className="contact-input mb-0"
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6 mb-25">
                      <input
                        className="contact-input mb-0"
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <span className="error">{errors.phone}</span>
                      )}
                    </div>

                    <div className="col-xl-6 mb-25">
                      <input
                        className="contact-input mb-0"
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      {errors.subject && (
                        <span className="error">{errors.subject}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mb-25">
                      <textarea

                        className="contact-input contact-input-messege mb-0"
                        name="message"
                        placeholder="Write Message"
                        // defaultValue={""}
                        value={formData.message}
                        onChange={handleChange}
                      />
                      {errors.message && (
                        <span className="error">{errors.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="subs-btn">
                        <button
                          className="theme-btn clr-1 theme-btn-bg"
                          to="/Contact"
                          type="submit"
                          id="submitbutton"
                        >
                          send message{" "}
                          <i className="lni lni-telegram-original" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* Contact Area */}
      <div className="google-map contact-map">
        <iframe
          className="w-100"
          title="The Shaftesbury Centre, Percy Street, Swindon, SN2 2AZ"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9954.125615456487!2d-0.20839!3d51.4116643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875febea0aa9abd%3A0xee384e6e0270ed19!2sA%20M%20L%20Benson%20Ltd%20Chartered%20Accountants!5e0!3m2!1sen!2sin!4v1704264883636!5m2!1sen!2sin"
        />
      </div>
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default Contact;
