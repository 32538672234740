import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function WorkingModels() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* contain */}
      <div className="vision-area pt-100"  id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
              <span>FEATURES</span>
              <span className="border-right-1" /> */}
              <h1 className="text-center">
                Working <span> Models</span>
              </h1>
              <p>
                At KRS Infotech, we specialize in executing result-oriented
                projects that drive success and exceed client expectations. We
                offer a wide range of contract options to suit our clients'
                unique needs, including Time and Materials (T&M), Statement of
                Work (SOW), Fixed Price (FP), Service Level Agreement (SLA),
                Staff Augmentation, and Managed Services Agreement (MSA).
              </p>
              <p>
                Our experienced team is committed to delivering customized and
                cost-effective service agreements that align perfectly with our
                clients' requirements. Whether you need a flexible T&M contract
                to adapt to evolving project demands, a clearly defined SOW
                contract to ensure transparency and accountability, a fixed
                price contract for budget control, or an SLA for ongoing
                support, we have tailored solutions for every business need.
              </p>
              <p>
                Additionally, our staff augmentation services provide the extra
                support and expertise your team requires, while our managed
                services agreements allow you to focus on core operations while
                we handle critical IT functions.
              </p>
              <p>
                At KRS Infotech, we understand that each project and client is
                unique. That's why we take a collaborative approach to design
                custom-made service agreements that maximize value, optimize
                efficiency, and enable your business to thrive. Our
                comprehensive understanding of various contract types, combined
                with our commitment to delivering outstanding results, positions
                us as the ideal partner to help you achieve your goals.
              </p>
              <p>
                Experience the power of result-oriented projects and
                personalized service agreements with KRS Infotech. Together, we
                will pave the way for your success in a cost-effective and
                tailored manner. Reach out to us today and let's embark on a
                journey of growth and excellence.
              </p>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /contain */}      
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default WorkingModels;
