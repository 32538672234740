import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";
import OwlCarousel from "react-owl-carousel";

function About() {
  const options = {
    loop: false,
    margin: 38,
    responsiveClass: true,
    // nav: true,
    autoplay: false,
    // smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  const options_strengths = {
    loop: true,
    // mouseDrag: false,
    touchDrag: true,
    stagePadding: 0,
    merge: true,
    // dots: true,
    slideBy: 2,
    dotsEach: 1,
    margin: 38,
    responsiveClass: true,
    // nav: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };

  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h3>About Us</h3>
                        {/* <nav
                          aria-label="breadcrumb"
                          className="d-inline-block mt-2 mb-15"
                        >
                          <ul className="breadcrumb-item-content mb-0">
                            <li className="breadcrumb-item">
                              <Link to="/">Home</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              About Us
                            </li>
                          </ul>
                        </nav> */}
                        <p
                          className="text-muted wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          Established in 2013, KRS Infotech has been at the
                          forefront of delivering exceptional technology
                          services across a wide spectrum of industries. With a
                          commitment to excellence and innovation, we have
                          established enduring partnerships with leading
                          entities, providing cutting-edge solutions and
                          expertise.
                        </p>
                        <p
                          className="text-muted wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          At KRS Infotech, we take great pride in delivering the
                          highest quality software design solutions to our
                          valued clients. With our team of experienced
                          professionals and a focus on innovation, we strive to
                          exceed customer expectations and provide cutting-edge
                          solutions that drive business growth. Our commitment
                          to quality is evident in every project we undertake,
                          as we leverage the latest technologies and industry
                          best practices to ensure the successful delivery of
                          software design solutions that are scalable,
                          efficient, and tailored to meet specific client needs.
                          Trust us to transform your ideas into reality with our
                          unwavering dedication to excellence, attention to
                          detail, and a customer-centric approach. Experience
                          the difference of KRS Infotech's best quality software
                          design solutions and unlock new possibilities for your
                          business.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/about.png"
                  alt="about"
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* About */}
      <div
        className="about-area pb-100 pt-mb-50 pt-sm-50 bg-section"
        style={{
          background: 'url("images/bg/bg-section-2.html") top center',
        }}
      >
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-6 align-self-center">
              <div className="mb-20">
                <img
                  src="images/down/Excellence.png"
                  className="img-fluid wow fadeInLeft animated"
                  alt="about"
                  data-wow-delay="0.2s"
                />
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 align-items-center">
              <div className="about-text">
                <div className="col-lg-12 text-center">
                  <div
                    className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
                    data-wow-delay="0.1s"
                  >
                    <span className="border-left-1" />
                    <span>ABOUT US</span>
                    <span className="border-right-1" />
                    <h1>
                      Elevating Excellence <span>at KRS Infotech</span>
                    </h1>
                  </div>
                </div>
                <h4>
                  Welcome to the heart of KRS Infotech, where innovation meets
                  commitment and excellence is our driving force. Established in
                  2013, KRS Infotech has evolved into a dynamic and
                  forward-thinking technology partner, spearheading exceptional
                  services across diverse industries.
                </h4>
                {/* <div className="pt-30 text-center">
                  <Link to="/" className="btn theme-btn">
                    Read More
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </div>
      {/* /About */}

      {/* Our Journey */}
      <div
        className="about-area pt-mb-50 pt-sm-50 bg-section"
        style={{
          background: 'url("images/bg/bg-section-2.html") top center',
        }}
      >
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-6 align-items-center">
              <div className="about-text">
                <div className="col-lg-12 text-center">
                  <div
                    className="section-title text-center ml-50 mr-50 mb-80 wow fadeInUp animated"
                    data-wow-delay="0.1s"
                  >
                    <span className="border-left-1" />
                    <span>Our Journey</span>
                    <span className="border-right-1" />
                    <h1>
                      A Legacy of <span>Innovation</span>
                    </h1>
                  </div>
                </div>
                <h4>
                  At KRS Infotech, our journey is marked by a relentless pursuit
                  of innovation and a commitment to delivering cutting-edge
                  solutions. We began with a vision to transform ideas into
                  reality, and today, we stand as a testament to the power of
                  innovation in shaping the digital landscape.
                </h4>
                {/* <div className="pt-30 text-center">
                  <Link to="/" className="btn theme-btn">
                    Read More
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-6 align-self-center">
              <div className="mb-20 ">
                <img
                  src="images/down/Innovation.png"
                  className="img-fluid wow fadeInLeft animated"
                  alt="about"
                  data-wow-delay="0.2s"
                  style={{ float: "right" }}
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </div>
      {/* /Our Journey */}
      {/* contain */}
      <div className="vision-area bg-gray-100" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80  wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
              <span>FEATURES</span>
              <span className="border-right-1" /> */}
              <h1 className="text-center mt-80">
                Award Winning <span> Support Team</span>
              </h1>
            </div>
            <p>
              At KRS Infotech, we take immense pride in our award-winning
              support team that is dedicated to meeting our customers' needs
              throughout the entire Software Development Life Cycle (SDLC). Our
              support team operates across all areas of the SDLC, providing
              comprehensive assistance and expertise at every stage of the
              software development process.
            </p>
            <p>
              With a deep understanding of industry best practices and years of
              experience, our support team is equipped to address a wide range
              of customer needs. From requirements gathering and analysis to
              design, development, testing, implementation, and ongoing
              maintenance, they are there to offer guidance, resolve issues, and
              ensure a seamless development journey.
            </p>
            <p>
              Our award-winning support team stands out for their exceptional
              knowledge, problem-solving abilities, and commitment to customer
              satisfaction. They are dedicated to providing timely and effective
              solutions, collaborating closely with clients to deliver results
              that exceed expectations.
            </p>
            <p>
              At KRS Infotech, we believe that remarkable support is vital to
              the success of any software project. Our support team's expertise
              in all areas of the SDLC brings added value to our clients,
              ensuring a smooth development process and the timely delivery of
              high-quality software solutions.
            </p>
            <p>
              Partner with KRS Infotech and experience the difference of our
              award-winning support team. We are ready to assist you at every
              step of the SDLC, providing top-notch support to help you achieve
              your project objectives and drive business growth.
            </p>
            <p>
              Feel free to contact us today and discover how our award-winning
              support team can elevate your software development journey.
            </p>

            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /contain */}
      {/* experienced professionals */}
      <div className="vision-area mb-80" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div
              className="section-title  ml-50 mr-50 mb-80 wow fadeInUp animated"
              data-wow-delay="0.1s"
            >
              {/* <span className="border-left-1" />
              <span>FEATURES</span>
              <span className="border-right-1" /> */}
              <h1 className="text-center mt-80">
                Experienced <span> Professionals</span>
              </h1>
            </div>
            <p>
              At KRS Infotech, we take immense pride in our ability to provide
              the best resources to our clients. Our comprehensive range of
              skilled professionals includes trained graduates, industry-leading
              technical staff, experienced mid-level to senior staff, and a
              dedicated team of consultants. We offer both permanent and
              contract staffing solutions to cater to diverse requirements.
            </p>
            <p>
              Flexibility and commitment are the hallmarks of our staff. We
              understand the evolving nature of technology and business demands,
              and our team is adaptable to meet the changing needs of our
              clients. Whether you require a specialized skill set for a
              short-term project or a long-term resource to drive strategic
              initiatives, we have the right personnel to fit your requirements.
            </p>
            <p>
              At KRS Infotech, we strongly believe in investing in our
              resources. We continuously upskill and nurture our team to ensure
              they stay at the forefront of industry trends and technologies. By
              investing in their professional development, we equip our team
              with the capabilities needed to deliver high-quality outcomes that
              exceed client expectations.
            </p>
            <p>
              Our commitment to providing the best resources is driven by our
              desire to remain competitive and offer exceptional value to our
              clients. We carefully assess the skills and expertise of our
              professionals to ensure they align with specific project needs.
              This attention to detail and focus on quality enable us to
              consistently deliver outstanding results.
            </p>
            <p>
              Choose KRS Infotech as your trusted partner, and experience the
              difference of our top-notch resources and dedicated team. We are
              dedicated to meeting your staffing needs with excellence,
              flexibility, and a commitment to delivering quality outcomes that
              drive your business forward.
            </p>
            <p>
              Contact us today to access the best resources for your project and
              discover the difference that KRS Infotech can make for your
              organization.
            </p>

            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /experienced professionals */}

      {/* Our strengths */}
      <div className="testimonial-area-03 pos-rel bg-gray-100 pb-90">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row mb-80">
            {/* col */}
            <div className="section-title text-center pr-20 pl-20 ">
              <h1 className="mt-80">
                Our <span>Strengths</span>
              </h1>
              {/* <h5>Customer Reviews</h5> */}
            </div>
            {/* /col */}
          </div>
          {/* /row */}
          {/* row */}
          <div className="row testimonial-active-02">
            <OwlCarousel
              className="owl-theme"
              {...options_strengths}
              margin={50}
            >
              <div className="item">
                {" "}
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      {/* <div className="testimonial-author__img author-testi mr-20">
                    <img src="images/testimonial/03.png" alt="" />
                  </div> */}
                      <div className="testimonial-author__content about-testi">
                        <h4>Our Commitment to Excellence</h4>
                        <span className="theme-color">
                          Shaping Futures, One Success Story at a Time
                        </span>
                      </div>
                    </div>
                    <p>
                      Excellence is not just a goal at KRS Infotech; it's the
                      standard by which we operate. Our unwavering dedication to
                      excellence is evident in every project we undertake, as we
                      blend creativity, expertise, and the latest technologies
                      to craft solutions that surpass expectations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 text_inner_02 pos-rel bg-white">
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      {/* <div className="testimonial-author__img author-testi mr-20">
                    <img src="images/testimonial/04.png" alt="" />
                  </div> */}
                      <div className="testimonial-author__content about-testi">
                        <h4>Building Enduring Partnerships</h4>
                        <span>Your Success, Our Mission</span>
                      </div>
                    </div>
                    <p>
                      At KRS Infotech, we don't just deliver solutions; we forge
                      enduring partnerships. Our approach goes beyond
                      transactions; we are invested in your success.
                      Collaborating closely with clients, we understand unique
                      challenges and tailor solutions that drive innovation and
                      transformation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      {/* <div className="testimonial-author__img author-testi mr-20">
                    <img src="images/testimonial/05.png" alt="" />
                  </div> */}
                      <div className="testimonial-author__content about-testi">
                        <h4>Serving Across Industries</h4>
                        <span>A Global Impact</span>
                      </div>
                    </div>
                    <p>
                      Collaborating with leading entities worldwide, KRS
                      Infotech has played a pivotal role in shaping the
                      infrastructure of banking, payments, and scheme
                      processing. From Nationwide Building Society to FIS Global
                      (Worldpay), Mastercard to Transport for Greater
                      Manchester, our impact extends globally.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      {/* <div className="testimonial-author__img author-testi mr-20">
                    <img src="images/testimonial/04.png" alt="" />
                  </div> */}
                      <div className="testimonial-author__content about-testi">
                        <h4>Our Values</h4>
                        <span>Integrity, Innovation, Impact</span>
                      </div>
                    </div>
                    <p>
                      Integrity is the bedrock of our operations. We uphold the
                      highest ethical standards, ensuring transparency, honesty,
                      and trust in all our interactions. Our commitment to
                      innovation drives us to explore new horizons, embrace
                      challenges, and pioneer solutions that make a meaningful
                      impact.
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Our strengths */}

      {/* Testimonial Area */}
      <div className="testimonial-area-03 pos-rel bg-gray-100  pb-90">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="section-title text-center pr-20 pl-20 mb-80">
                {/* <h5>Customer Reviews</h5> */}
                <h2>
                  Customer <span style={{ color: "#f84257" }}>Reviews</span>
                </h2>
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
          {/* row */}

          <div className="row testimonial-active-02 ">
            <OwlCarousel
              className="owl-theme"
              {...options}
              margin={50}
              dots={true}
              dotsEach={1}
            >
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="section-title text-center pr-20 pl-20 mb-80">
                      {/* <h5>Customer Reviews</h5> */}
                      <h2>"Amazing Support and Quality Work!"</h2>
                    </div>
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      <div className="testimonial-author__img author-testi mr-20">
                        <img
                          src="images/testimonial/testimonial.jpg"
                          alt="review"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="testimonial-author__content about-testi">
                        <h4>James Glashan</h4>
                        <span className="theme-color">ECREATIVE</span>
                      </div>
                    </div>
                    <p>
                      KRSInfotech has saved me so much time, spear-heading this
                      project for us and liaising directly with our American
                      office and third-party suppliers, such as ISPs, to ensure
                      that no stone is left unturned. They are a great bunch of
                      guys – always on hand to assist with their knowledge and
                      expertise – and I would have no hesitation in recommending
                      their IT support services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="section-title text-center pr-20 pl-20 mb-80">
                      {/* <h5>Customer Reviews</h5> */}
                      <h2>"Cutting-Edge Solutions and Exceptional Service"</h2>
                    </div>
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      <div className="testimonial-author__img author-testi mr-20">
                        <img
                          src="images/testimonial/01.png"
                          alt="review"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="testimonial-author__content about-testi">
                        <h4>Arthur Belnt</h4>
                        {/* <span className="theme-color">ECREATIVE</span> */}
                      </div>
                    </div>
                    <p>
                      Our experience with KRS Infotech has been nothing short of
                      exceptional. They delivered cutting-edge solutions that
                      revolutionized our IT infrastructure. The team's technical
                      prowess, coupled with their commitment to client
                      satisfaction, sets them apart. From swift implementation
                      to ongoing support, KRS Infotech has proven to be a
                      reliable partner in our business success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="section-title text-center pr-20 pl-20 mb-80">
                      {/* <h5>Customer Reviews</h5> */}
                      <h2>"Seamless Integration and Proactive Support"</h2>
                    </div>
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      <div className="testimonial-author__img author-testi mr-20">
                        <img
                          src="images/testimonial/02.png"
                          alt="review"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="testimonial-author__content about-testi">
                        <h4>Harry Neown</h4>
                        {/* <span className="theme-color">ECREATIVE</span> */}
                      </div>
                    </div>
                    <p>
                      Kudos to KRS Infotech for seamlessly integrating their IT
                      solutions into our operations. The transition was smooth,
                      and their team's attention to detail ensured minimal
                      disruptions. What truly sets them apart is their proactive
                      support – always one step ahead, addressing issues before
                      they become problems. KRS Infotech is not just an IT
                      provider; they are a strategic partner invested in our
                      long-term success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="text_inner text_inner_02 pos-rel bg-white">
                    <div className="section-title text-center pr-20 pl-20 mb-80">
                      {/* <h5>Customer Reviews</h5> */}
                      <h2>"Responsive, Reliable, and Results-Driven"</h2>
                    </div>
                    <div className="testimonial-author d-flex align-items-center mb-30">
                      <div className="testimonial-author__img author-testi mr-20">
                        <img
                          src="images/testimonial/06.jpg"
                          alt="review"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="testimonial-author__content about-testi">
                        <h4>Oliver Kijal</h4>
                        {/* <span className="theme-color">ECREATIVE</span> */}
                      </div>
                    </div>
                    <p>
                      Working with KRS Infotech has been a game-changer for our
                      business. Their responsiveness to our needs and commitment
                      to delivering results is commendable. The team not only
                      resolved our immediate IT challenges but also provided
                      scalable solutions for future growth. KRS Infotech has
                      become an integral part of our success story, and we
                      highly recommend their services to any business looking
                      for a reliable and results-driven IT partner.
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
            {/* <ul className="slick-dots" role="tablist">
              <li className="" role="presentation">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control00"
                  aria-controls="slick-slide00"
                  aria-label="1 of 2"
                  tabindex="-1"
                >
                  1
                </button>
              </li>
              <li role="presentation" className="">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control01"
                  aria-controls="slick-slide01"
                  aria-label="2 of 2"
                  tabindex="-1"
                >
                  2
                </button>
              </li>
              <li role="presentation" className="">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control02"
                  aria-controls="slick-slide02"
                  aria-label="3 of 2"
                  tabindex="-1"
                >
                  3
                </button>
              </li>
              <li role="presentation" className="slick-active">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control03"
                  aria-controls="slick-slide03"
                  aria-label="4 of 2"
                  tabindex="0"
                  aria-selected="true"
                >
                  4
                </button>
              </li>
            </ul> */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Testimonial Area */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default About;
