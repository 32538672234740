import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";
import { Link } from "react-router-dom";

function Services() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <h3>Services</h3>
                        {/* <nav
                          aria-label="breadcrumb"
                          className="d-inline-block mt-2 mb-15"
                        >
                          <ul className="breadcrumb-item-content mb-0">
                            <li className="breadcrumb-item">
                              <Link to="/">Home</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Services
                            </li>
                          </ul>
                        </nav> */}
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h2 className="section-title">
                            We provide a wide range of Services
                          </h2>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          We offer variety of ecommerce technologies that can
                          match to any industry and any requirement. Shopify,
                          Magento, Woocommerce and more technologies that can
                          suit any size or any requirement.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/service.png"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* <!-- work --> */}
      <div className="work-area pb-100 pt-mb-50 pt-sm-50 pb-mb-50 pb-sm-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-6">
              {/* col */}
              <div className="col-lg-12">
                <div
                  className="section-title text-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <span className="border-left-1" />
                  <span>Our Services</span>
                  <span className="border-right-1" />
                  <h1>
                    <span>Empowering Your </span>Digital Journey
                  </h1>
                </div>
              </div>
              <p className="wow fadeInUp animated pt-30" data-wow-delay="0.2s">
                Welcome to KRS Infotech, where we offer a comprehensive range of
                services designed to empower your digital success. Our dedicated
                team is committed to delivering innovative solutions tailored to
                meet the unique needs of your business.
              </p>

              {/* col */}
            </div>
            <div className="col-lg-6">
              <img
                src="images/down/Empowering.png"
                alt=""
                className="wow fadeInRight animated"
                style={{ float: "right" }}
              />
            </div>
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </div>
      {/* <!-- /work --> */}
      {/* Services all */}

      <div className="features-area pb-75">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row justify-content-center">
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/SoftwareDevelopment">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                  <div className="icon bg5">
                    {/* <i className="las la-cloud" /> */}
                    <img
                      src="images/icon/developer.png"
                      alt="crm"
                      className="mt-3"
                    />
                  </div>
                  <h3>Software Development and DevOps Services</h3>
                  <p className="par-6">
                    {" "}
                    Empower your business with agile development and seamless
                    DevOps practices. Our services cover a wide spectrum of
                    technologies, including Java, .NET, C#, Python, and mobile
                    development technologies, ensuring adaptive, scalable, and
                    responsive solutions.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/TestingServices">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <div className="icon bg4">
                    <i className="las la-money-bill" />
                    <img
                      src="images/icon/testing-icon.png"
                      alt="crm"
                      className="mt-3"
                    />
                  </div>
                  <h3>
                    Testing Services: Elevating Quality Through Testing
                    Excellence
                  </h3>
                  <p className="par-6">
                    {" "}
                    Ensure the quality and reliability of your applications with
                    our high-quality testing services. From manual to
                    automation, our specialized focus on mobile and digital
                    technologies guarantees rigorous testing for uncompromising
                    performance.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/Consulting">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <img
                      src="images/icon/consulting-icon.png"
                      alt="crm"
                      className="mt-3"
                    />
                    {/* <i className="las la-cloud" /> */}
                  </div>
                  <h3>CONSULTING SERVICES</h3>
                  <br />
                  <p className="par-6">
                    {" "}
                    We offer IT consulting services that will help you improve
                    your software architecture, create a tech-driven digital
                    strategy, and improve operations by optimising your software
                    portfolio. Our software engineers will finish your digital
                    transformation journey through careful planning and
                    effective execution of the outlined IT strategy.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/Sapmodules">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.6s"
                >
                  <div className="icon bg3">
                    {/* <i className="las la-leaf" /> */}
                    <img
                      src="images/icon/sap-icon.png"
                      alt="crm"
                      className="mt-3"
                    />
                  </div>
                  <h3>SAP Services: Empowering Your SAP Journey</h3>
                  <p className="par-6">
                    {" "}
                    Navigate your SAP transformation with confidence. Our
                    specialized SAP services cover IT & ERP strategy, solutions
                    advisory, licensing support, systems integration, holistic
                    implementation, quality assurance, and SAP ECC to SAP HANA
                    migration expertise.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/BusinessOperations">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                  <div className="icon bg2">
                    {/* <i className="las la-layer-group" /> */}
                    <img
                      src="images/icon/help.png"
                      alt="crm"
                      className="mt-3"
                    />
                  </div>
                  <h3>24/7 Business Operations Support</h3>
                  <br />
                  <p className="par-6">
                    {" "}
                    Ensure the seamless operation of your business with our 24/7
                    live support. Our dedicated support team is always ready to
                    address technical issues, provide expert guidance, and
                    assist with any challenges that may arise.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/CRMExpression">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <div>
                      <img
                        src="images/icon/crm.png"
                        alt="crm"
                        className="mt-3"
                      />
                    </div>
                    {/* <i className="lni lni-eye" /> */}
                  </div>
                  <h3>CRMExpression Software</h3>
                  <br />
                  <p className="par-6">
                    {" "}
                    For businesses of all sizes, acquiring, retaining, and
                    supporting customers is more challenging than ever
                    before.Activities that were already complex have become
                    highly-complicated, multi-faceted workflows and procedures
                    that are difficult to coordinate, track and manage.
                    Marketing teams must plan and develop an increasing number
                    of sophisticated campaigns, and deliver them through
                    multiple mediums. Sales reps must follow-up on hundreds of
                    new leads, Support staff must rapidly resolve a growing
                    volume of customer problems and issues. And, management must
                    oversee customer-facing operations across all departments,
                    and ensure that all client interactions are handled in a
                    responsive and protective manner.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
            {/* col */}
            <div className="col-lg-4 col-sm-6 col-md-6">
              <Link to="/SoftwareDesign">
                <div
                  className="features-item with-border wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    {/* <i className="lni lni-eye" /> */}
                    <img
                      src="images/icon/Software-Design.png"
                      alt="crm"
                      className="mt-3"
                    />
                  </div>
                  <h3>Software Design Solutions</h3>
                  <br />
                  <p className="par-6">
                    {" "}
                    Transform your ideas into reality with our high-quality
                    software design solutions. Our experienced professionals
                    leverage the latest technologies and industry best practices
                    to deliver scalable, efficient, and customized software
                    solutions.{" "}
                  </p>
                </div>
              </Link>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Services all */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default Services;
