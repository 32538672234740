import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function SoftwareDevelopment() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h2 className="section-title">
                            Empowering Solutions Through Agile Development and
                            DevOps
                          </h2>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          At KRS Infotech, we are committed to delivering
                          excellence in software development, paired with
                          efficient continuous integration and seamless DevOps
                          practices. Our specialized services cover a wide
                          spectrum of technologies, including Java, .NET, C#,
                          Python, as well as mobile development technologies,
                          ensuring that our offerings are tailored to meet
                          diverse technological demands.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/Development.png"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* work */}
      <div className="work-area pb-100 pt-mb-50 pt-sm-50 pb-mb-50 pb-sm-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-12">
              {/* col */}
              <div className="col-lg-12">
                <div
                  className="section-title-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <h3>
                    Continuous Integration and{" "}
                    <span style={{ color: "#f84257" }}>DevOps Automation</span>
                  </h3>
                  <div className="points">
                    <ul>
                      <li
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        <i className="las la-check" />
                        <p>
                          We understand the pivotal roles of seamless
                          integration and automation in modern software
                          development. At KRS Infotech, our team is well-versed
                          in a range of cutting-edge DevOps automation tools,
                          including but not limited to Jenkins, GitLab CI/CD,
                          CircleCI, and Travis CI, among others. This empowers
                          us to create automated and optimized workflows that
                          reinforce our commitment to agile delivery and DevOps
                          practices.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="section-title-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <h3>
                    Empowering Development Across{" "}
                    <span style={{ color: "#f84257" }}>
                      Multiple Technologies
                    </span>
                  </h3>
                  <div className="points">
                    <ul>
                      <li
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        <i className="las la-check" />
                        <p>
                          Our expertise spans numerous technology domains, from
                          Java and .NET to C#, Python, and mobile development
                          technologies. With a focus on maximizing efficiency
                          and quality, our development services are tailored to
                          meet the diverse needs of our clients, ensuring that
                          their vision materializes into robust, resilient, and
                          scalable solutions.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="section-title-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <h3>
                    Expertise in Agile and{" "}
                    <span style={{ color: "#f84257" }}>Automation</span>
                  </h3>
                  <div className="points">
                    <ul>
                      <li
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        <i className="las la-check" />
                        <p>
                          Adapting to the dynamic nature of modern development,
                          our team specializes in organizing testing efforts
                          within agile environments, working in continuous
                          integration workflows, and embracing automation to
                          streamline delivery. This approach ensures that your
                          solutions are robust, resilient, and ready for the
                          demands of the digital era.
                        </p>
                      </li>
                      <li
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        <i className="las la-check" />
                        <p>
                          At KRS Infotech, we don't just test; we optimize,
                          fortify, and elevate your digital assets through
                          meticulous, tailored testing practices.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* col */}
            </div>
          </div>
          {/* /row */}
        </div>
      </div>
      {/* /work */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default SoftwareDevelopment;
