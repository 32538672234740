import React from "react";
import Header from "./componet/Header";
import Footer from "./componet/Footer";

function TestingServices() {
  return (
    <>
      {/* Header */}
      <Header />
      {/* /Header */}
      {/* Breadcrumb */}
      <div className="hero-1-bg" id="home">
        {/* Container */}
        <div className="container">
          {/* row */}
          <div className="row align-items-center justify-content-center">
            {/* col */}
            <div className="col-lg-6">
              <div className="breadcrumb">
                {/* container */}
                <div className="container">
                  {/* row */}
                  <div className="row mt-5">
                    {/* /col */}
                    <div className="col-lg-12 wow fadeInUp animated">
                      <div className="title-heading text-start">
                        {/* <h3>Software Design Solutions</h3> */}
                        {/* <nav
                  aria-label="breadcrumb"
                  className="d-inline-block mt-2 mb-15"
                >
                  <ul className="breadcrumb-item-content mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Services</Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      CRMExpression
                    </li>
                  </ul>
                </nav> */}
                        <div
                          className="section-heading wow fadeInUp animated"
                          data-wow-delay="0.2s"
                        >
                          <h2 className="section-title">
                            Testing Services: Elevating Quality Through Testing
                            Excellence
                          </h2>
                        </div>
                        <p
                          className="text-muted mb-30 pb-5 wow fadeInUp animated"
                          data-wow-delay="0.3s"
                        >
                          At KRS Infotech, we take pride in delivering
                          high-quality testing services, ranging from manual to
                          automation, tailored to meet the ever-evolving demands
                          of the digital landscape. Our specialized focus on
                          mobile and digital technologies ensures that your
                          applications and systems are rigorously tested to
                          deliver uncompromising performance and reliability.
                        </p>
                      </div>
                    </div>
                    {/* /col */}
                  </div>
                  {/* /row */}
                </div>
                {/* /container */}
              </div>
            </div>
            {/*- /col */}
            {/* col */}
            <div className="col-lg-6 col-md-10">
              <div
                className="mt-5 mt-lg-0 wow fadeInUp animated"
                data-wow-delay="0.2s"
              >
                <img
                  src="images/down/testing.jpg"
                  alt=""
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Breadcrumb */}

      {/* work */}
      <div className="work-area pb-100 pt-mb-50 pt-sm-50 pb-mb-50 pb-sm-50">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-lg-12">
              {/* col */}
              <div className="col-lg-12">
                <div
                  className="section-title-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <h3>
                    Comprehensive Testing{" "}
                    <span style={{ color: "#f84257" }}>Solutions</span>
                  </h3>
                  <div className="points">
                    <ul>
                      <li
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        <i className="las la-check" />
                        <p>
                          From program test management to expert test managers,
                          KRS Infotech offers a comprehensive suite of testing
                          solutions aimed at providing the best quality services
                          for your business needs. With a dedicated testing
                          practice, our team is continually engaged in process
                          improvement, helping clients seamlessly adapt agile
                          models, continuous integration, and automation,
                          ultimately reducing the time to production.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="section-title-left wow fadeInUp animated"
                  data-wow-delay="0.2s"
                >
                  <h3>
                    Tailored Testing{" "}
                    <span style={{ color: "#f84257" }}>Practices</span>
                  </h3>
                  <div className="points">
                    <ul>
                      <li
                        className="wow fadeInUp animated"
                        data-wow-delay="0.4s"
                      >
                        <i className="las la-check" />
                        <p>
                          Our testing services encompass a wide array of
                          offerings, ensuring that your applications and systems
                          are meticulously evaluated to meet the highest quality
                          standards. We provide various types of testing
                          services, including functional testing, performance
                          testing, security testing, usability testing, and
                          more, with a focus on delivering meticulous, thorough
                          assessments to bolster your digital assets.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* col */}
            </div>
          </div>
          {/* /row */}
        </div>
      </div>
      {/* /work */}
      {/* Footer */}
      <Footer />
      {/* /Footer */}
    </>
  );
}

export default TestingServices;
